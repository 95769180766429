const firebase = window.firebase;

const firebaseConfig = {
  apiKey: "AIzaSyBSvkgWvv-sAdZl76VTF_zLky8Tzr3IFwA",
  authDomain: "karlekstanken-3c89c.firebaseapp.com",
  databaseURL: "https://karlekstanken-3c89c.firebaseio.com",
  projectId: "karlekstanken-3c89c",
  storageBucket: "karlekstanken-3c89c.appspot.com",
  messagingSenderId: "75337626405",
  appId: "1:75337626405:web:dc8f168b1263fa184e20cc",
  measurementId: "G-5F9YPXMJPB"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();
//TODO: disable when testing, enable when publishing
analytics.setAnalyticsCollectionEnabled(true);

const db = app.firestore();
db.enablePersistence().then(() => {
  console.log("firestore offline persistence enabled");
}).catch((e) => (console.log(e)));

const auth = app.auth();
const functions = app.functions("europe-west1");
// Executes cloud functions from locally running functions emulator
//functions.useFunctionsEmulator("http://localhost:5001");
const EmailAuthProvider = firebase.auth.EmailAuthProvider;
const ServerTimestamp = firebase.firestore.Timestamp;
const FieldValue = firebase.firestore.FieldValue;
export { app, analytics, db, auth, functions, EmailAuthProvider, ServerTimestamp, FieldValue };